import { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const StatesContext = createContext();

const useStatesData = () => useContext(StatesContext);

const StatesState = ({ children }) => {
  const [token] = useState(localStorage.getItem('token'));
  const [currentURLID, setCurrentURLID] = useState();
  const [currentState, setCurrentState] = useState();
  const [states, setStates] = useState([]);

  useEffect(() => {
    const getStates = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_STATES_API}/states`);
        setStates(data);
      } catch (error) {
        toast.error(error.response?.data.error || error.message);
      }
    };
    getStates();
  }, []);

  useEffect(() => {
    const getState = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}`);
        setCurrentState(data);
      } catch (error) {
        toast.error(error.response?.data.error || error.message);
      }
    };
    currentURLID ? getState() : setCurrentState();
  }, [currentURLID]);

  const updateState = async datos => {
    try {
      const {
        data: { success }
      } = await axios.put(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}`, datos, {
        headers: { Authorization: token }
      });
      setCurrentState(prev => ({
        ...prev,
        ...datos
      }));
      toast.success(success);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const updateArmonizacion = async datos => {
    try {
      const {
        data: { success }
      } = await axios.put(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}/armonizacion`, datos, {
        headers: { Authorization: token }
      });
      setCurrentState(prev => ({
        ...prev,
        rubros: prev.rubros.map(rubro => (rubro.name === 'Armonización Legislativa' ? { ...rubro, datos } : rubro))
      }));
      toast.success(success);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const updateModificacion = async datos => {
    try {
      const {
        data: { success }
      } = await axios.put(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}/modificacion`, datos, {
        headers: { Authorization: token }
      });
      setCurrentState(prev => ({
        ...prev,
        rubros: prev.rubros.map(rubro =>
          rubro.name === 'Modificación de Estatutos' ? { ...rubro, Modificacion: datos } : rubro
        )
      }));
      toast.success(success);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const updateCFCRLIndividual = async (datos, { setValues }) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_STATES_API}/states/${currentURLID}/cfcrl-individual`,
        datos,
        {
          headers: { Authorization: token }
        }
      );
      setCurrentState(prev => ({
        ...prev,
        rubros: prev.rubros.map(rubro =>
          rubro.name === 'Oficinas de la CFCRL' ? { ...rubro, individual: data } : rubro
        )
      }));
      setValues({ individual: data });
      toast.success('CFCRL individual actualizada');
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const deleteCFCRLIndividual = async sede => {
    try {
      const {
        data: { success }
      } = await axios.delete(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}/cfcrl-individual/${sede}`, {
        headers: { Authorization: token }
      });
      setCurrentState(prev => ({
        ...prev,
        rubros: prev.rubros.map(rubro =>
          rubro.name === 'Oficinas de la CFCRL'
            ? { ...rubro, individual: rubro.individual.filter(rubro => rubro.id !== sede) }
            : rubro
        )
      }));
      toast.success(success);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const updateCFCRLColectiva = async datos => {
    try {
      const {
        data: { success }
      } = await axios.put(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}/cfcrl-colectiva`, datos, {
        headers: { Authorization: token }
      });
      setCurrentState(prev => ({
        ...prev,
        rubros: prev.rubros.map(rubro =>
          rubro.name === 'Oficinas de la CFCRL' ? { ...rubro, colectiva: datos } : rubro
        )
      }));
      toast.success(success);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const updateCentrosIndividual = async (datos, { setFieldValue }) => {
    try {
      const {
        data: { success, dataValues }
      } = await axios.put(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}/centros-individual`, datos, {
        headers: { Authorization: token }
      });
      setCurrentState(prev => ({
        ...prev,
        rubros: prev.rubros.map(rubro =>
          rubro.name === 'Centros de Conciliación Locales' ? { ...rubro, individual: datos } : rubro
        )
      }));
      setFieldValue('id', dataValues.id);
      toast.success(success);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const updateCentrosColectiva = async (datos, { setFieldValue }) => {
    try {
      const {
        data: { success, dataValues }
      } = await axios.put(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}/centros-colectiva`, datos, {
        headers: { Authorization: token }
      });
      setCurrentState(prev => ({
        ...prev,
        rubros: prev.rubros.map(rubro =>
          rubro.name === 'Centros de Conciliación Locales' ? { ...rubro, colectiva: datos } : rubro
        )
      }));
      setFieldValue('id', dataValues.id);
      toast.success(success);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const updateTribFedIndividual = async (datos, { setFieldValue }) => {
    try {
      const {
        data: { success, dataValues }
      } = await axios.put(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}/tribfed-individual`, datos, {
        headers: { Authorization: token }
      });
      setCurrentState(prev => ({
        ...prev,
        rubros: prev.rubros.map(rubro =>
          rubro.name === 'Tribunales Laborales Federales' ? { ...rubro, individual: datos } : rubro
        )
      }));
      setFieldValue('id', dataValues.id);
      toast.success(success);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const updateTribFedColectiva = async (datos, { setFieldValue }) => {
    try {
      const {
        data: { success, dataValues }
      } = await axios.put(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}/tribfed-colectiva`, datos, {
        headers: { Authorization: token }
      });
      setCurrentState(prev => ({
        ...prev,
        rubros: prev.rubros.map(rubro =>
          rubro.name === 'Tribunales Laborales Federales' ? { ...rubro, individual: datos } : rubro
        )
      }));
      setFieldValue('id', dataValues.id);
      toast.success(success);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const updateTribLocIndividual = async (datos, { setFieldValue }) => {
    try {
      const {
        data: { success, dataValues }
      } = await axios.put(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}/tribloc-individual`, datos, {
        headers: { Authorization: token }
      });
      setCurrentState(prev => ({
        ...prev,
        rubros: prev.rubros.map(rubro =>
          rubro.name === 'Tribunales Laborales Locales' ? { ...rubro, individual: datos } : rubro
        )
      }));
      setFieldValue('id', dataValues.id);
      toast.success(success);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const updateTribLocColectiva = async (datos, { setFieldValue }) => {
    try {
      const {
        data: { success, dataValues }
      } = await axios.put(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}/tribloc-colectiva`, datos, {
        headers: { Authorization: token }
      });
      setCurrentState(prev => ({
        ...prev,
        rubros: prev.rubros.map(rubro =>
          rubro.name === 'Tribunales Laborales Locales' ? { ...rubro, individual: datos } : rubro
        )
      }));
      setFieldValue('id', dataValues.id);
      toast.success(success);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const updateSedes = async (datos, { setValues }) => {
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}/sede`, datos, {
        headers: { Authorization: token }
      });
      console.log(data);
      setCurrentState(prev => ({
        ...prev,
        Sedes: data
      }));
      setValues({ sedes: data });
      toast.success('Sedes actualizadas');
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const deleteSede = async sede => {
    try {
      const {
        data: { success }
      } = await axios.delete(`${process.env.REACT_APP_STATES_API}/states/${currentURLID}/sede/${sede}`, {
        headers: { Authorization: token }
      });
      setCurrentState(prev => ({
        ...prev,
        Sedes: prev.Sedes.filter(sede => sede.id !== sede)
      }));
      toast.success(success);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  const updateBatch = async file => {
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);
      const {
        data: { success }
      } = await axios.put(`${process.env.REACT_APP_STATES_API}/states`, formData, {
        headers: { Authorization: token }
      });
      toast.success(success);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
    }
  };

  return (
    <StatesContext.Provider
      value={{
        states,
        setCurrentURLID,
        currentState,
        updateState,
        updateArmonizacion,
        updateModificacion,
        updateCFCRLIndividual,
        deleteCFCRLIndividual,
        updateCFCRLColectiva,
        updateCentrosIndividual,
        updateCentrosColectiva,
        updateTribFedIndividual,
        updateTribFedColectiva,
        updateTribLocIndividual,
        updateTribLocColectiva,
        updateSedes,
        deleteSede,
        updateBatch
      }}
    >
      {children}
    </StatesContext.Provider>
  );
};

export { StatesState as default, useStatesData };
