import EditarCentrosIndividual from './EditarCentrosIndividual';
import EditarCentrosColectiva from './EditarCentrosColectiva';

const EditarCentrosConciliacion = ({ datos }) => {
  const { individual, colectiva } = datos;
  return (
    <div>
      <EditarCentrosIndividual datos={individual} />
      <EditarCentrosColectiva datos={colectiva} />
    </div>
  );
};

export default EditarCentrosConciliacion;
