import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Loading from '../Loading';
import DatosGenerales from './DatosGenerales';
import EditarArmonizacion from './EditarArmonizacion';
import EditarModificacion from './EditarModificacion';
import EditarCFCRL from './EditarCFCRL';
import EditarCentrosConciliacion from './EditarCentrosConciliacion';
import EditarTribFed from './EditarTribFed';
import EditarTribLoc from './EditarTribLoc';
import Sedes from './Sedes';
import { useStatesData } from '../../context/StatesContext';
import 'react-tabs/style/react-tabs.css';

const StateSingle = () => {
  const { currentState, setCurrentURLID } = useStatesData();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentURLID(id);
    return () => setCurrentURLID();
  }, [id, setCurrentURLID]);

  const renderEditor = ({ name, ...rest }) => {
    switch (name) {
      case 'Armonización Legislativa':
        return <EditarArmonizacion datos={rest.datos} />;
      case 'Modificación de Estatutos':
        return <EditarModificacion datos={rest.Modificacion} />;
      case 'Oficinas del CFCRL':
        return <EditarCFCRL datos={rest} />;
      case 'Centros de Conciliación Locales':
        return <EditarCentrosConciliacion datos={rest} />;
      case 'Tribunales Laborales Federales':
        return <EditarTribFed datos={rest} />;
      case 'Tribunales Laborales Locales':
        return <EditarTribLoc datos={rest} />;
      default:
        return 'En construcción';
    }
  };

  return currentState ? (
    <>
      <button className='btn' onClick={() => navigate('/admin/estados')}>
        &laquo; Regresar a lista de estados
      </button>
      <h2 className='text-center'>{currentState.name}</h2>
      <div className='row justify-content-center'>
        <Tabs>
          <TabList>
            <Tab>General</Tab>
            {currentState.rubros.map((rubro, i) => (
              <Tab key={i}>{rubro.name}</Tab>
            ))}
            <Tab>Sedes</Tab>
          </TabList>
          <TabPanel>
            <DatosGenerales />
          </TabPanel>
          {currentState.rubros.map((rubro, i) => (
            <TabPanel key={i}>
              <h2 className='text-center'>{rubro.name}</h2>
              {renderEditor(rubro)}
            </TabPanel>
          ))}
          <TabPanel>
            <Sedes />
          </TabPanel>
        </Tabs>
      </div>
    </>
  ) : (
    <Loading />
  );
};

export default StateSingle;
