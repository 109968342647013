import { useRef, useEffect, useCallback } from 'react';

const Map = ({ setCurrentState, statesData, sedes, filter, setPopup }) => {
  const mapRef = useRef();

  const getStage = useCallback(
    id => {
      const { etapa } = statesData.find(state => id === state.code);
      return etapa;
    },
    [statesData]
  );

  useEffect(() => {
    window.simplemaps_countrymap.load(mapRef);
    const { main_settings } = window.simplemaps_countrymap_mapdata;
    main_settings.state_hover_color = '#404041';
  }, []);

  useEffect(() => {
    const renderMap = () => {
      const { state_specific } = window.simplemaps_countrymap_mapdata;
      const { refresh } = window.simplemaps_countrymap;
      for (let state in state_specific) {
        const etapa = getStage(state);
        state_specific[state].color = '#e3e3e3';
        state_specific[state].popup = 'off';
        if (etapa === 1 && filter.primera) {
          state_specific[state].color = '#235b4e';
        } else if (etapa === 2 && filter.segunda) {
          state_specific[state].color = '#bc955c';
        } else if (etapa === 3 && filter.tercera) {
          state_specific[state].color = '#ddc9a3';
        }
      }
      const locations = {};
      for (let sede in sedes) {
        locations[sede] = locations[sede] || {};
        locations[sede].name = sedes[sede][0].name;
        locations[sede].lat = sedes[sede][0].Municipio.lat;
        locations[sede].lng = sedes[sede][0].Municipio.lon;
        locations[sede].display = 'state';
        locations[sede].description = `<div class='location-card'>
                                          <h5 class='text-center subtitle-card'>${sedes[sede][0].name}</h5>
                                          <div class='scrollable-content'>
                                            ${sedes[sede]
                                              .map(sede => {
                                                const getTipo = () =>
                                                  sede.tipo === 'cfcrl'
                                                    ? 'OFICINA ESTATAL DEL CENTRO FEDERAL DE CONCILIACIÓN Y REGISTRO LABORAL'
                                                    : sede.tipo === 'conciliacion'
                                                    ? 'CENTRO DE CONCILIACIÓN ESTATAL'
                                                    : sede.tipo === 'tribfed'
                                                    ? 'TRIBUNALES LABORALES FEDERALES'
                                                    : 'TRIBUNALES LABORALES ESTATALES';
                                                return `<div>
                                                          <h6 class='title-card'>${getTipo()}</h6>
                                                          <div><strong>Sede: </strong>${sede.sede}</div>
                                                          <div><strong>Domicilio: </strong>${sede.domicilio}</div>
                                                          ${sede.tel && `<div><strong>Teléfono: </strong>${sede.tel}</div>`}
                                                        </div>`;
                                              })
                                              .join('')}
                                          </div>
                                        </div>`;
      }
      
      window.simplemaps_countrymap_mapdata.locations = locations;
      setCurrentState();
      refresh();
    };
    statesData.length && sedes && renderMap();
  }, [statesData, sedes, filter, setCurrentState, getStage]);

  const handleClick = e => {
    if (e.target.nodeName === 'path' && e.target.classList.item(0).startsWith('sm_state_')) {
      const currentState = e.target.classList.item(0).replace('sm_state_', '');
      const { state_specific } = window.simplemaps_countrymap_mapdata;
      const { state_zoom, refresh_state } = window.simplemaps_countrymap;
      for (let state in state_specific) {
        const etapa = getStage(state);
        state_specific[state].color = '#e3e3e3';
        state_specific[state].popup = 'off';
        if (etapa === 1 && filter.primera) {
          state_specific[state].color = '#235b4e';
        } else if (etapa === 2 && filter.segunda) {
          state_specific[state].color = '#bc955c';
        } else if (etapa === 3 && filter.tercera) {
          state_specific[state].color = '#ddc9a3';
        }
        refresh_state(state);
      }
      state_specific[currentState].color = '#404041';
      refresh_state(currentState);
      state_zoom(currentState);
      setCurrentState(currentState);
    }
    if (e.target.nodeName === 'path' && e.target.classList.item(0).startsWith('sm_location_')) {
      setPopup(true);
    }
    if (e.target.hasAttribute('alt') && e.target.alt === 'Close') {
      setPopup(false);
    }
  };

  return (
    <>
      <div id='map' ref={mapRef} onClick={handleClick} onTouchStart={handleClick}></div>
      <svg height='0' width='0' xmlns='http://www.w3.org/2000/svg'>
        <filter id='drop-shadow'>
          <feGaussianBlur in='SourceAlpha' stdDeviation='4' />
          <feOffset dx='-10' dy='10' result='offsetblur' />
          <feFlood floodColor='rgba(127, 152, 145, 0.45)' />
          <feComposite in2='offsetblur' operator='in' />
          <feMerge>
            <feMergeNode />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </svg>
    </>
  );
};

export default Map;
