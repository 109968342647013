import EditarTribFedIndividual from './EditarTribFedIndividual';
import EditarTribFedColectiva from './EditarTribFedColectiva';

const EditarTribFed = ({ datos }) => {
  const { individual, colectiva } = datos;
  return (
    <div>
      <EditarTribFedIndividual datos={individual} />
      <EditarTribFedColectiva datos={colectiva} />
    </div>
  );
};

export default EditarTribFed;
