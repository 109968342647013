import { Formik, Form, Field } from 'formik';
import { useStatesData } from '../../context/StatesContext';

const EditarTribLocColectiva = ({ datos }) => {
  const { updateTribLocColectiva } = useStatesData();
  const initialValues = datos || {
    total_conciliados: 0,
    total_tramite: 0
  };

  const handleSubmit = async (values, actions) => updateTribLocColectiva(values, actions);

  return (
    <>
      <h4 className='text-center mt-5'>Materia Colectiva</h4>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <div className='row  justify-content-center align-items-center mt-3'>
            <div className='col-md-6'>
              <label htmlFor='total_tramite' className='form-label'>
                Demandas presentadas:
              </label>
              <Field name='total_tramite' className='form-control' type='number' />
            </div>
            <div className='col-md-6'>
              <label htmlFor='total_conciliados' className='form-label'>
                Sentencias emitidas:
              </label>
              <Field name='total_conciliados' className='form-control' type='number' />
            </div>
            <div className='row mt-5 justify-content-center'>
              <button className='col-md-4 btn btn-success btn-block' type='submit'>
                Guardar
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default EditarTribLocColectiva;
