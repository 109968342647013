import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const AuthContext = createContext();

const useAuth = () => useContext(AuthContext);

const AuthState = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loading, setLoading] = useState(false);

  const from = location.state?.from?.pathname || '/admin';

  useEffect(() => {
    const persistLogin = async () => {
      if (token) {
        try {
          setLoading(true);
          const { user, errorMsg } = await getUserInfo(token);
          if (errorMsg) throw new Error(errorMsg);
          setUser(user);
          setIsAuthenticated(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.response?.data.error || error.message);
          setLoading(false);
        }
      }
    };
    persistLogin();
  }, [token]);

  const getUsers = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${process.env.REACT_APP_STATES_API}/users`, {
        headers: { Authorization: token }
      });
      setLoading(false);
      return data;
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
      setLoading(false);
    }
  };

  const getUserInfo = async token => {
    try {
      setLoading(true);
      const { data: user } = await axios.get(`${process.env.REACT_APP_STATES_API}/users/me`, {
        headers: { Authorization: token }
      });
      setLoading(false);
      return { user };
    } catch (error) {
      let errorMsg;
      if (error.response) {
        errorMsg = error.response.data.error;
      } else {
        errorMsg = error.message;
      }
      setLoading(false);
      return { errorMsg };
    }
  };

  const signIn = async data => {
    try {
      setLoading(true);
      const {
        data: { token }
      } = await axios.post(`${process.env.REACT_APP_STATES_API}/users/signin`, data);
      localStorage.setItem('token', token);
      setToken(token);
      const { user, errorMsg } = await getUserInfo(token);
      if (errorMsg) throw new Error(errorMsg);
      setUser(user);
      setIsAuthenticated(true);
      navigate(from, { replace: true });
      setLoading(false);
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
      setLoading(false);
    }
  };

  const signOut = () => {
    localStorage.removeItem('token');
    setToken(null);
    setUser();
    setIsAuthenticated(false);
  };

  const createUser = async formData => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${process.env.REACT_APP_STATES_API}/users`, formData, {
        headers: { Authorization: token }
      });
      setLoading(false);
      return data;
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
      setLoading(false);
    }
  };

  const generatePassword = async id => {
    try {
      setLoading(true);
      const { data } = await axios.put(`${process.env.REACT_APP_STATES_API}/users/${id}`, null, {
        headers: { Authorization: token }
      });
      setLoading(false);
      return data;
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
      setLoading(false);
    }
  };

  const deleteUser = async id => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${process.env.REACT_APP_STATES_API}/users/${id}`, {
        headers: { Authorization: token }
      });
      setLoading(false);
      return data;
    } catch (error) {
      toast.error(error.response?.data.error || error.message);
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ signIn, signOut, isAuthenticated, user, getUsers, createUser, generatePassword, deleteUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthState as default, useAuth };
