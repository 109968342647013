import { Formik, Form, Field } from 'formik';
import { useStatesData } from '../../context/StatesContext';

const EditarModificacion = ({ datos }) => {
  const { updateModificacion } = useStatesData();
  const initialValues = datos;

  const handleSubmit = async values => updateModificacion(values);

  return (
    <>
      <h4 className='text-center'>
        Porcentaje de avance: {parseFloat((datos.modificados * 100) / datos.organizaciones).toFixed(1)} %
      </h4>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <div className='row  justify-content-center align-items-center mt-3'>
            <div className='col-md-4'>
              <label htmlFor='modificados' className='form-label'>
                Modificados:
              </label>
              <Field name='modificados' className='form-control' type='number' />
            </div>
            <div className='col-md-4'>
              <label htmlFor='organizaciones' className='form-label'>
                Organizaciones:
              </label>
              <Field name='organizaciones' className='form-control' type='number' />
            </div>
            <div className='row mt-5 justify-content-center'>
              <button className='col-md-4 btn btn-success btn-block' type='submit'>
                Guardar
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default EditarModificacion;
