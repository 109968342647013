import { Routes, Route, Navigate } from 'react-router-dom';
import MapDashboard from './components/Mapa/MapDashboard';
import Login from './components/Auth/Login';
import Layout from './components/Layout';
import StatesList from './components/Admin/StatesList';
import StateSingle from './components/Admin/StateSingle.js';
import Usuarios from './components/Admin/Usuarios.js';
import CargaArchivo from './components/Admin/CargaArchivo';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import './App.css';

const App = () => {
  return (
    <Routes>
      <Route path='/'>
        <Route index element={<MapDashboard />} />
        <Route path='admin' element={<Layout />}>
          <Route index element={<Login />} />
          <Route path='estados' element={<ProtectedRoute />}>
            <Route index element={<StatesList />} />
            <Route path=':id' element={<StateSingle />} />
            <Route path='carga' element={<CargaArchivo />} />
          </Route>
          <Route path='usuarios' element={<ProtectedRoute />}>
            <Route index element={<Usuarios />} />
          </Route>
          <Route path='*' element={<Navigate to='/admin' />} />
        </Route>
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default App;
