import tribunales from "../../assets/tribunalesloc.svg";

const TribunalesLocales = ({
  cardData: {
    showTribunalesLocalesColec,
    altTribunalesLocalesColec,
    showTribunalesLocalesInd,
    altTribunalesLocalesInd,
    TribunalesLocalesIndividual,
    TribunalesLocalesColectiva,
  },
}) => {
  return (
    <div className="row flex-column">
      <div className="col mb-4">
        <div className="row justify-content-center">
          <img
            src={tribunales}
            alt="Tribunales laborales locales"
            className="w-25"
          />
        </div>
      </div>
      <div className="col">
        {showTribunalesLocalesInd &&
        TribunalesLocalesIndividual &&
        TribunalesLocalesIndividual.total_tramite !== 0 ? (
          <>
            <h6 className="brief text-center">Asuntos individuales</h6>
            <div className="row justify-content-center">
              <div className="col">
                <div className="row flex-column justify-content-between align-items-center">
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {TribunalesLocalesIndividual.total_tramite}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Demandas presentadas
                  </div>
                  <br></br>
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {TribunalesLocalesIndividual.tasa_resolucion}%
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Tasa de resolución
                  </div>
                </div>
              </div>

              <div className="col">
              <div className="row flex-column justify-content-around align-items-center">
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {TribunalesLocalesIndividual.total_conciliados}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Sentencias emitidas
                  </div>
                </div>
                <br></br>
                <div className="row">
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {TribunalesLocalesIndividual.fuera_sentencia}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Asuntos resueltos fuera de sentencia
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col">
                {/* Espacio vacío */}
                <br></br>
                <div>
                  <h3
                    className="text-center brief title-card"
                    style={{ fontWeight: "900" }}
                  >
                    {TribunalesLocalesIndividual.total_jueces}
                  </h3>
                </div>
                <div className="text-center" style={{ fontSize: "smaller" }}>
                  Numero total de jueces
                </div>
              </div>
              <div class="col">
                {/* Contenido para colocar debajo del espacio vacío */}
                <br></br>
                <div>
                  <h3
                    className="text-center brief title-card"
                    style={{ fontWeight: "900" }}
                  >
                    {TribunalesLocalesIndividual.hombres}
                  </h3>
                </div>
                <div className="text-center" style={{ fontSize: "smaller" }}>
                  Hombres
                </div>
              </div>
              <div class="col">
                {/* Otro contenido para colocar debajo del espacio vacío */}
                <br></br>
                <div>
                  <h3
                    className="text-center brief title-card"
                    style={{ fontWeight: "900" }}
                  >
                    {TribunalesLocalesIndividual.mujeres}
                  </h3>
                </div>
                <div className="text-center" style={{ fontSize: "smaller" }}>
                  Mujeres
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col">
                {/* Espacio vacío */}
                <br></br>
                <div>
                  <h3
                    className="text-center brief title-card"
                    style={{ fontWeight: "900" }}
                  >
                    {TribunalesLocalesIndividual.total_actuarios}
                  </h3>
                </div>
                <div className="text-center" style={{ fontSize: "smaller" }}>
                  Numero total de Actuarios
                </div>
              </div>
              <div class="col">
                {/* Contenido para colocar debajo del espacio vacío */}
                <br></br>
                <div>
                  <h3
                    className="text-center brief title-card"
                    style={{ fontWeight: "900" }}
                  >
                    {TribunalesLocalesIndividual.hombres_actuarios}
                  </h3>
                </div>
                <div className="text-center" style={{ fontSize: "smaller" }}>
                  Hombres
                </div>
              </div>
              <div class="col">
                {/* Contenido para colocar debajo del espacio vacío */}
                <br></br>
                <div>
                  <h3
                    className="text-center brief title-card"
                    style={{ fontWeight: "900" }}
                  >
                    {TribunalesLocalesIndividual.mujeres_actuarias}
                  </h3>
                </div>
                <div className="text-center" style={{ fontSize: "smaller" }}>
                  Mujeres
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col">
                {/* Espacio vacío */}
                <br></br>
                <div>
                  <h3
                    className="text-center brief title-card"
                    style={{ fontWeight: "900" }}
                  >
                    {TribunalesLocalesIndividual.total_secretarios_instructores}
                  </h3>
                </div>
                <div className="text-center" style={{ fontSize: "smaller" }}>
                  Numero total de Secretarios Instructores
                </div>
              </div>
              <div class="col">
                {/* Contenido para colocar debajo del espacio vacío */}
                <br></br>
                <div>
                  <h3
                    className="text-center brief title-card"
                    style={{ fontWeight: "900" }}
                  >
                    {TribunalesLocalesIndividual.hombres_instructores}
                  </h3>
                </div>
                <div className="text-center" style={{ fontSize: "smaller" }}>
                  Hombres
                </div>
              </div>
              <div class="col">
                {/* Contenido para colocar debajo del espacio vacío */}
                <br></br>
                <div>
                  <h3
                    className="text-center brief title-card"
                    style={{ fontWeight: "900" }}
                  >
                    {TribunalesLocalesIndividual.mujeres_instructoras}
                  </h3>
                </div>
                <div className="text-center" style={{ fontSize: "smaller" }}>
                  Mujeres
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="row flex-column">
            <div className="col">
            </div>
          </div>
        )}

        {showTribunalesLocalesColec && TribunalesLocalesColectiva.total_tramite !== 0 &&TribunalesLocalesColectiva ? (
          <>
            <h6 className="brief text-center mt-3">Asuntos colectivos</h6>
            <div className="row justify-content-center">
              <div className="col-md-5">
                <div className="row flex-column justify-content-between align-items-center">
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {TribunalesLocalesColectiva.total_tramite}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Demandas presentadas
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row flex-column justify-content-around align-items-center">
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {TribunalesLocalesColectiva.total_conciliados}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Sentencias emitidas
                  </div>
                </div>
              </div>
              {/* <div className='col-md-5'>
                <div className='row flex-column justify-content-around align-items-center'>
                  <div>
                  <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>70 <span style={{ fontSize: 'small' }}>%</span></h3>
                  </div>
                  <div className='text-center' style={{ fontSize: 'smaller' }}>
                    Porcentaje de conciliación
                  </div>
                </div>
              </div> */}
            </div>
          </>
        ) : (
          <div className="row flex-column">
            <div className="col">
              <h6 className="brief text-center">{altTribunalesLocalesColec}</h6>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TribunalesLocales;
