import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { toast } from 'react-toastify';
import { useStatesData } from '../../context/StatesContext';
import Loading from '../Loading';

const CargaArchivo = () => {
  const [loading, setLoading] = useState(false);
  const { updateBatch } = useStatesData();

  const handleChange = async file => {
    setLoading(true);
    await updateBatch(file);
    setLoading(false);
  };

  if (loading) return <Loading />;
  return (
    <div className='container d-flex justify-content-center mt-100'>
      <div className='row'>
        <FileUploader
          multiple={false}
          label='Seleccione un archivo o arrástrelo aquí'
          hoverTitle='Soltar aquí'
          onTypeError={() => toast.error('Solo se permiten archivos .XLS o .XLSX')}
          handleChange={handleChange}
          name='file'
          types={['XLS', 'XLSX']}
        />
      </div>
    </div>
  );
};

export default CargaArchivo;
