import { Formik, Form, Field } from 'formik';
import { useStatesData } from '../../context/StatesContext';

const EditarTribFedIndividual = ({ datos }) => {
  const { updateTribFedIndividual } = useStatesData();
  const initialValues = datos || {
    total_conciliados: 0,
    total_tramite: 0,
    fuera_sentencia: 0,
    tasa_resolucion: 0,
    total_jueces: 0,
    hombres: 0,
    mujeres: 0,
  };

  const handleSubmit = async (values, actions) => updateTribFedIndividual(values, actions);

  return (
    <>
      <h4 className='text-center mt-5'>Materia Individual</h4>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <div className='row  justify-content-center align-items-center mt-3'>
            <div className='col-md-3'>
              <label htmlFor='total_tramite' className='form-label'>
                Demandas presentadas:
              </label>
              <Field name='total_tramite' className='form-control' type='number' />
            </div>
            <div className='col-md-3'>
              <label htmlFor='total_conciliados' className='form-label'>
                Sentencias emitidas:
              </label>
              <Field name='total_conciliados' className='form-control' type='number' />
            </div>
            <div className='col-md-3'>
              <label htmlFor='fuera_sentencia' className='form-label'>
                Asuntos resueltos fuera de sentencia:
              </label>
              <Field name='fuera_sentencia' className='form-control' type='number' />
            </div>
            <div className='col-md-3'>
              <label htmlFor='tasa_resolucion' className='form-label'>
                Tasa de resolución:
              </label>
              <Field name='tasa_resolucion' className='form-control' type='number' />
            </div>
            <div className='col-md-3'>
              <label htmlFor='total_jueces' className='form-label'>
                Número total de jueces:
              </label>
              <Field name='total_jueces' className='form-control' type='number' />
            </div>
            <div className='col-md-3'>
              <label htmlFor='hombres' className='form-label'>
                Hombres:
              </label>
              <Field name='hombres' className='form-control' type='number' />
            </div>
            <div className='col-md-3'>
              <label htmlFor='mujeres' className='form-label'>
                Mujeres:
              </label>
              <Field name='mujeres' className='form-control' type='number' />
            </div>
            <div className='row mt-5 justify-content-center'>
              <button className='col-md-4 btn btn-success btn-block' type='submit'>
                Guardar
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default EditarTribFedIndividual;
