import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const NavBar = () => {
  const { user, isAuthenticated, signOut } = useAuth();
  return (
    <nav className='navbar navbar-expand-md navbar-dark bg-dark' aria-label='Navigation'>
      <div className='container-fluid'>
        <Link to='/admin' className='d-none d-md-block navbar-brand'>
          Avances Reforma a la Justicia Laboral
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#menu'
          aria-controls='menu'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div className='collapse navbar-collapse' id='menu'>
          {isAuthenticated && (
            <ul className='navbar-nav ms-auto mb-2 mb-md-0'>
              <li className='nav-item'>
                <div className='nav-link'>Bienvenido {user.name}</div>
              </li>
              <li className='nav-item'>
                <Link to='/admin/estados' className='nav-link'>
                  Estados
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/admin/estados/carga' className='nav-link'>
                  Carga por archivo
                </Link>
              </li>
              {user.role === 'admin' && (
                <li className='nav-item'>
                  <Link to='/admin/usuarios' className='nav-link'>
                    Usuarios
                  </Link>
                </li>
              )}
              <li className='nav-item' onClick={signOut} style={{ cursor: 'pointer' }}>
                <div className='nav-link'>Cerrar sesión</div>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
