import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Loading = () => {
  return (
    <div className='row justify-content-center'>
      <div className='col-1'>
        <Loader type='Circles' color='#2a5c4d' height={100} width={100} />
      </div>
    </div>
  );
};

export default Loading;
