import EditarCFCRLIndividual from './EditarCFCRLIndividual';
import EditarCFCRLColectiva from './EditarCFCRLColectiva';

const EditarCFCRL = ({ datos }) => {
  const { individual, colectiva } = datos;
  return (
    <div>
      {!!individual && <EditarCFCRLIndividual datos={individual} />}
      {!!colectiva && <EditarCFCRLColectiva datos={colectiva} />}
    </div>
  );
};

export default EditarCFCRL;
