import { useForm } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import Loading from '../Loading';

const Login = () => {
  const { isAuthenticated, loading, signIn } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/admin/estados';

  if (loading) return <Loading />;
  if (isAuthenticated) return <Navigate to={from} replace />;

  return (
    <div className='row justify-content-center'>
      <div className='col-md-4'>
        <form onSubmit={handleSubmit(signIn)}>
          <div className='mb-3'>
            <label htmlFor='email' className='form-label'>
              Correo electrónico
            </label>
            <input
              type='email'
              className={errors.email ? 'form-control is-invalid' : 'form-control'}
              {...register('email', { required: true })}
            />
            {errors.email && <div className='invalid-feedback'>Correo electrónico es requerido</div>}
          </div>
          <div className='mb-3'>
            <label htmlFor='password' className='form-label'>
              Contraseña
            </label>
            <input
              type='password'
              className={errors.password ? 'form-control is-invalid' : 'form-control'}
              {...register('password', { required: true })}
            />
            {errors.password && <div className='invalid-feedback'>Contraseña es requerida</div>}
          </div>
          <button type='submit' className='btn btn-success'>
            Iniciar sesión
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
